import React, { useEffect, useState } from 'react';
import Link from 'next/link';

import styles from './HeroRealtyApp.module.scss';

type Props = {
    propertyTodayCount?: number;
    totalAgentBriefs?: number;
    totalAlertsCount?: number;
};

const HeroRealtyApp = ({ propertyTodayCount = 0, totalAgentBriefs = 0, totalAlertsCount = 0 }: Props) => {
    const words = [{ text: 'Listings' }, { text: 'Briefs' }, { text: 'Alerts' }, { text: 'Deals' }];
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [displayWord, setDisplayWord] = useState('');
    const typingSpeed = 150;
    const eraseSpeed = 100;
    const pauseTime = 1000;
    const [isErasing, setIsErasing] = useState(false);
    const cursorVisible = true;

    const [animatedPropertyCount, setAnimatedPropertyCount] = useState(0);
    const [animatedAgentBriefsCount, setAnimatedAgentBriefsCount] = useState(0);
    const [animatedAlertsCount, setAnimatedAlertsCount] = useState(0);
    const countSpeed = 100;

    const animateCount = (targetValue: number, setter: React.Dispatch<React.SetStateAction<number>>) => {
        let currentValue = targetValue * 0.8;
        setter(Math.floor(currentValue));
        const step = (targetValue - currentValue) / 5;

        const interval = setInterval(() => {
            currentValue += step;
            if (currentValue >= targetValue) {
                setter(targetValue);
                clearInterval(interval);
            } else {
                setter(Math.floor(currentValue));
            }
        }, countSpeed);
    };

    useEffect(() => {
        animateCount(propertyTodayCount, setAnimatedPropertyCount);
        animateCount(totalAgentBriefs, setAnimatedAgentBriefsCount);
        animateCount(totalAlertsCount, setAnimatedAlertsCount);
    }, [propertyTodayCount, totalAgentBriefs, totalAlertsCount]);

    useEffect(() => {
        const word = words[currentWordIndex].text;
        let timer: NodeJS.Timeout;

        if (!isErasing && displayWord !== word) {
            timer = setTimeout(() => {
                if (displayWord.length === 0) {
                    setTimeout(() => {
                        setDisplayWord(word.slice(0, 1));
                    }, 1000);
                } else {
                    setDisplayWord(word.slice(0, displayWord.length + 1));
                }
            }, typingSpeed);
        } else if (isErasing && displayWord !== '') {
            timer = setTimeout(() => {
                setDisplayWord(word.slice(0, displayWord.length - 1));
            }, eraseSpeed);
        } else if (!isErasing && displayWord === word) {
            timer = setTimeout(() => {
                setIsErasing(true);
            }, pauseTime);
        } else if (isErasing && displayWord === '') {
            setIsErasing(false);
            setCurrentWordIndex((prev) => (prev + 1) % words.length);
        }

        return () => clearTimeout(timer);
    }, [displayWord, isErasing, currentWordIndex, words]);

    return (
        <div className="w-full px-4 pb-16 pt-8 md:px-14 md:pb-28 md:pt-20">
            <div className="flex flex-col lg:flex-row lg:justify-between">
                <div className="flex flex-col gap-8 lg:w-1/2">
                    <div className="flex flex-col gap-2 md:gap-4">
                        <div className="flex flex-col gap-2">
                            <div className="flex">
                                <h1
                                    className={`${styles.heroTitle} flex flex-col gap-3 text-5xl font-medium leading-tight text-white md:text-[56px]`}
                                >
                                    <span className="flex flex-col gap-x-4 md:flex-row">
                                        <span>Where</span>
                                        <span className={styles.displayWord}>
                                            {displayWord}
                                            <span
                                                className={
                                                    cursorVisible
                                                        ? `${styles.cursorSpace} ${styles.blink}`
                                                        : styles.cursorSpace
                                                }
                                            >
                                                |
                                            </span>
                                        </span>
                                    </span>
                                    <span>Happen</span>
                                </h1>
                            </div>

                            <h2 className="text-lg font-normal leading-9 text-neutral-300 md:text-left">
                                Connecting Agents. Sourcing Deals.
                            </h2>
                        </div>

                        <div className="flex w-full justify-center lg:hidden">
                            <iframe
                                src="https://player.vimeo.com/video/1034060504?autoplay=1&muted=1&dnt=1&transparent=1&title=0&controls=1"
                                className="h-[300px] w-full rounded-lg sm:h-[400px] lg:h-full"
                                title="Video"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>

                        <div className="flex flex-col gap-8 md:gap-12">
                            <div className="flex w-full flex-wrap gap-x-4">
                                {animatedPropertyCount > 0 && (
                                    <div className="flex flex-wrap gap-2 md:items-center">
                                        <h2 className="flex items-center text-xl font-semibold text-white">
                                            <Link
                                                href="https://www.realty.com.au/discover"
                                                passHref
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles.link}
                                            >
                                                Listings Today
                                            </Link>
                                        </h2>

                                        <h1
                                            className="bg-gradient-to-r from-purple-500 via-purple-500 to-purple-600 bg-clip-text text-2xl font-semibold text-transparent"
                                            style={{ minWidth: '70px' }}
                                        >
                                            {animatedPropertyCount.toLocaleString()}
                                        </h1>
                                    </div>
                                )}

                                {animatedAgentBriefsCount > 0 && (
                                    <div className="flex flex-wrap gap-2 md:items-center">
                                        <h2 className="flex items-center text-xl font-semibold text-white">
                                            <Link
                                                href="https://buyer.realty.com.au/search-briefs/list"
                                                passHref
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className={styles.linkActive}
                                            >
                                                Active Briefs
                                            </Link>
                                        </h2>

                                        <h1
                                            className="bg-gradient-to-r from-purple-500 via-purple-500 to-purple-600 bg-clip-text text-2xl font-semibold text-transparent"
                                            style={{ minWidth: '50px' }}
                                        >
                                            {animatedAgentBriefsCount.toLocaleString()}
                                        </h1>
                                    </div>
                                )}

                                {animatedAlertsCount > 0 && (
                                    <div className="flex flex-wrap gap-2 md:items-center">
                                        <h2 className="flex items-center text-xl font-semibold text-white">
                                            Total Alerts
                                        </h2>

                                        <h1
                                            className="bg-gradient-to-r from-purple-500 via-purple-500 to-purple-600 bg-clip-text text-2xl font-semibold text-transparent"
                                            style={{ minWidth: '70px' }}
                                        >
                                            {animatedAlertsCount.toLocaleString()}
                                        </h1>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="mt-6 flex w-full flex-wrap gap-4">
                            <Link
                                href="https://www.realty.com.au/sign-up"
                                passHref
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex h-14 w-full items-center justify-center rounded-lg bg-gradient-to-l from-purple-500 to-purple-900 text-base font-medium text-white transition-all hover:from-purple-600 hover:to-purple-800 hover:shadow-lg hover:shadow-purple-500/50 md:w-auto md:px-12"
                            >
                                Get Started
                            </Link>

                            <Link
                                href="https://buyer.realty.com.au/find/directory"
                                passHref
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex h-14 w-full items-center justify-center rounded-lg border border-white bg-transparent text-base font-medium text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50 md:w-auto md:px-12"
                            >
                                Find a Buyers Agent
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col-reverse lg:w-1/2 lg:flex-col">
                    <iframe
                        src="https://player.vimeo.com/video/1034060504?autoplay=1&muted=1&dnt=1&transparent=1&title=0&controls=1"
                        className="hidden h-full w-full rounded-lg lg:block"
                        title="Video"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    />
                </div>
            </div>
        </div>
    );
};

export default HeroRealtyApp;
